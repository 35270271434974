import React, {useState, useRef} from 'react';
import axios from 'axios'
import EssayList from './EssayList';

const Blog: React.FC = () => {
  const [email, setEmail] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState('');

  let [captchaToken, setCaptchaToken] = useState(null);
  let captchaRef = useRef<any>(null);

  interface Window {
    grecaptcha: any;
    test: () => void;
  }

  const verify = () => {
    captchaRef.current.getResponse().then((res: any) => {
      setCaptchaToken(res)
    })
  }




  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError('');

    (window as any).grecaptcha.ready(function () {
      (window as any).grecaptcha.execute(process.env.REACT_APP_RECAPTCHA_APP_SITE_KEY, {action: 'submit'}).then(async function (token: any) {
        console.log("sending with token", token);
        // Add your logic to submit to your backend server here.
        await axios.post(process.env.REACT_APP_API_URL + '/addemail', {token, email})
          .then((res) => {
            setSubmitted(true);
            console.log(res);
          })
          .catch((error) => {
            console.log(error);
          }

          );
      });
    });
  };

  return (
    <div>
      <p>I write sometimes. You can find my tech related writing here. If you find it interesting, feel free to leave your email address to get the occaional update, including new blog posts.</p>
      {submitted ? (
        <p>Thank you for subscribing!</p>
      ) : (
        <>
          <form onSubmit={handleSubmit}>
            <input
              type="email"
              name="email"
              placeholder="Your Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <button type="submit">Subscribe</button>
          </form>
          <div className="g-recaptcha"
            data-sitekey="6Ldrb4slAAAAAAxTur3CUpW8EnPR6TEXDPBN-dER"
            data-callback="onSubmit"
            data-size="invisible">
          </div>

          {error && <p>{error}</p>}
        </>
      )}
      <h3>Essays</h3>
      <EssayList />
      <div className="recaptcha">

        This site is protected by reCAPTCHA and the Google
        <a href="https://policies.google.com/privacy" target="_blank" rel="noopener noreferrer">Privacy Policy</a> and
        <a href="https://policies.google.com/terms" target="_blank" rel="noopener noreferrer">Terms of Service</a> apply.
      </div>
    </div>
  );
};

export default Blog;
