import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar';
import Title from './components/Title';
import Home from './components/Home';
import Projects from './components/Projects';
import Blog from './components/Blog';
import HireMe from './components/HireMe';
import Essay from './components/Essay';

const App: React.FC = () => {
  return (
    <Router>
      <div className="container">
        <nav className="box navbar">
          <Navbar />
        </nav>
        <header className="box title">
          <Title />
        </header>
        <main className="box content">
          <Routes>
          <Route path="/" element={<Home/>} />
            <Route path="/projects" element={<Projects/>} />
            <Route path="/essays" element={<Blog/>} />
            <Route path="/essays/:essayFilename" element={<Essay/>} />
            <Route path="/hire-me" element={<HireMe/>} />
          </Routes>
        </main>
      </div>
    </Router>
  );
};

export default App;
