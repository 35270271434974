import React from 'react';

const Home: React.FC = () => {
  return (
    <div>
      <p>I’m a product manager, full-stack engineer, and multidisciplinary artist currently based in NYC. I’ve been building tech in some form for over 15 years now, starting with my first attempt at a ‘startup’ when I was 12. I bloodied myself on hackathons in college, then dropped out after my sophomore year to do Y Combinator in summer of 2015. I’ve been doing things in and out of the tech sphere ever since. </p>

      <p>Outside of tech I enjoy event organizing and writing. I've run a well regarded series of queer parties in berlin, organized pride marches, and helped start the world's largest collegiate hackathon. Most of my writing is focused on niche queer fiction. Two of my stories have sparked small fandoms, and one of them continues to sit near the top of its genre. </p>

      <p>I also produce music sometimes and I used to dance competitively.</p>

      <p>I’m currently at OpenAI, where I'm on a small team building the future of corporate work.</p>
    </div>
  );
};

export default Home;
