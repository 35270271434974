import React from 'react';

const HireMe: React.FC = () => {
  return (
    <div>
      I'm currently happily at OpenAI. In fact, we're hiring ourselves! Our team is mostly ex-founders—if you're highly technical and interested in the future of corporate work in an AI world, reach out to julia@ this domain.
      {/* <p>I’m particularly interested in projects that bring machines and humans closer together. That includes everything from brain machine interfaces to AI based toolings like Copilot or ChatGPT. Technology will continue to advance, and humans will have to grow with it or be left behind. As someone who’s put considerable effort into transcending the body they were given birth, there’s an aspect of liberation for me here as well.</p> */}

      {/* <p>I’m most useful in novel problem spaces, where my ability to navigate complexity and adapt to changing circumstances can shine. I have an extremely technical background (Sr. Eng/technical founder before moving into product), I’ve worked in high stakes spaces constrained by rapidly evolving regulations, and I bring an extremely diverse set of life experience into understanding how users and society interact with technology. I care about great teams, personal growth, and making impact. I've really enjoyed digging back into code over the summer, so I'm open to both technical and product roles.</p> */}


      {/* <p>If this sounds like you, feel free to reach out to me at julia@.</p> */}
    </div>
  );
};

export default HireMe;
