import React from 'react';
import solstory from './solstory.png';
import pantry from './pantry.png';
import hackathon from './hackathon.jpeg';

const Projects: React.FC = () => {
  return (
    <div>
      <h2>Projects</h2>
      <p>There’s probably dozens of things that could fit on this page. Here’s some that I’m particularly proud of.</p>

      <h3>Pantry (Summer 2023)</h3>

      <p>Pantry is a combined package manager + runner for Large Language Models. Out of the box it includes a UI, a CLI, and a remote API, for downloading, installing, and running local LLMs.</p>

      <p>Pantry uses an actor framework to run multiple LLMs in parallel, handles session creation/caching/restoration dynamically, and includes a fleshed out permissions system for external apps. You can use it either as a tool for downloading and running LLMs more easily on your machine, or integrate it into your LLM—based software, to eliminate the need to build custom LLM management into your own project.</p>

      <p>I'm no longer actively maintaining/expanding Pantry, but <a href="https://github.com/JuliaMerz/pantry">here's the github repo.</a></p>
      <img alt="The Pantry UI" src={pantry} />

      <h3>Solstory (Spring 2022)</h3>

      <p>Solstory was a Solana POC for tamper-evident, medium agnostic, off chain storage. In 2022 Solana was incredibly cheap to run compute on, but due to chain design, incredibly expensive to store any information (at the time, storing a tweet cost ~$1). But storing data off-chain defeated all the security and cryptographic guarantees that made on-chain development interesting.</p>

      <p>This bothered me, so I wrote a Solana program that allowed services to attach temper-evident data storage side chains to NFTs—by default on ARWeave. It also abstracted all of this complexity under a javascript API, letting services skip any actual blockchain development and just register, attach, or retrieve data with a single API call each on server and on client. Some nifty optimization reduced the per-attachment scaling cost to just 75 bytes, no matter how long the chain of records.</p>

      <p>The Solana API has continued to evolve, and as a POC I have no interest in maintaining it, but you can find <i>a lot</i> of architecture documentation <a href="https://solstoryorg.github.io/soldocusaur/">here</a>.</p>

      <img alt="The Solstory architecture" src={solstory} />
      <p><i>I have not purchased and do not own any NFTs, and my technical interest here should not be taken as an endorsement of crypto. I just like distributed systems.</i></p>


      <h3>Even Responsible Finance (Jan 2019-Aug 2021)</h3>

      <p>I joined Even as a senior engineer. Nine months later the company was in desperate need of product management, so I became the company’s sole PM in Sept ’19.  For the next two year I was responsible for both our most popular product and general upkeep (everything from customer success with the worlds largest retailer to financial infrastructure to regulatory compliance). Helping 500k active users survive the challenges of being a low income American just a little bit better is still one of my favorite career experiences.</p>

      <p>Personal highlight: I PM’d our COVID-19 response, taking a new product from requirements to ready-to-ship in under four weeks <i>without</i> the engineering team working weekends. Getting there required extremely careful sequencing to parallelize engineering components I was confident wouldn't change while managing stakeholders—sales, customer success, compliance, legal, finance, execs—work to nail down the rest.</p>

      <h3>Blitz eSports (2014-2016)</h3>

      <p>Went through Y Combinator, raised a seed round, pioneered a product still used by millions of users. All the usual startup-y things.</p>

      <p>The one project that I’m most proud of, on a technical level: We built a tool to statistically determine the optimal League of Legends build order—the progenitor of the product that's still used by millions today—based on your character, your opponents, and where you were facing off. This was an interesting statistical problem, since with ~200 items, and ~100^2 character combinations, the permutational complexity is impossible to store in a database completely. It’s also an interesting programmatic one, since the database storing the calculations needs to take in 10s of games a second. In the middle is the question: if a game finishes with build a-b-c, should a-b-c-d, a-b-c-d-e, a-b all be given a win, and if so, how?</p>

      <p>I solved for the statistical problem by breaking out common sub build orders and storing exact sequences only for the early part of the game—when precise order matters. Then I rewrote part of Django’s database wrapper so we could take advantage of Postgres’ upsert functionality and batch records. Heavy use of filtering queries limited us to upserting precisely what we wanted, and writing the entire system using Django’s F() statements pushed the calculations into the database layer—letting us update dozens of records on a single filtered query without ever retrieving them.</p>

      <h3>Cal Hacks (2014-2015)</h3>
      <p>Cal Hacks was, when we started it and for a while afterwords, the world's largest collegiate hackathon.</p>
      <p>The event was originally intended as a one-off project by a group of friends, but in spring of 2015 a few of us wanted to continue it. I took over with the mandate to build the infrastructure for an enduring organization, before handing off the org when I dropped out to do Y Combinator. <a href="https://calhacks.io">Cal Hacks</a> continues to operate to this day, and represents my longest lasting legacy at UC Berkeley.</p>
      <img src={hackathon} />
      <p style={{"textAlign": "center"}}><i>Picture credit: Ryan Serpa from <a href="https://www.dailycal.org/2014/10/05/hackers-gather-36-hour-hackathon-california-memorial-stadium">The Daily Californian</a>.</i></p>


      <h3>YoTheWorld (2014)</h3>
      <p>YoTheWorld was effectively a social engineering project, designed to take the prize money of the Yo hackathon (an app that earned some notoriety in 2014 for having just a single function: to ‘yo’ at someone) and redirect it towards charity.</p>

      <p>We ran a social media campaign to get the most ‘users’ (people who had yo’d at us) with the promise that we would only ever send them a single ‘yo’ back: if we won. Got mentioned in <a href="https://www.businessinsider.com/yotheworld-hackathon-idea-2014-7">Business Insider</a> and <a href="https://www.theguardian.com/technology/2014/jul/02/yo-app-windows-phone-iftt">The Guardian</a>, and sent $2500 to pay for children’s heart surgery in Haiti.</p>


    </div>
  );
};

export default Projects;
