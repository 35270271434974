import React from 'react';
import { Link } from 'react-router-dom';

const essayContext = require.context('../../essays/', true, /\.md$/);
console.log("Essay Ctx", essayContext);


// Credits to ChatGPT
function toApaTitleCase(input: string): string {
    const isMajorWord = (word: string): boolean => {
        if (word.length > 3) return true;

        const lowerCaseWord = word.toLowerCase();
        const minorWords = [
            "and", "as", "but", "for", "if", "nor", "or", "so", "yet",
            "a", "an", "the",
            "as", "at", "by", "for", "in", "of", "off", "on", "per", "to", "up", "via"
        ];

        return !minorWords.includes(lowerCaseWord);
    };

    const words = input.split(' ');

    const titleCasedWords = words.map((word, index) => {
        const firstLetter = word.charAt(0).toUpperCase();
        const restOfWord = word.slice(1).toLowerCase();

        if (index === 0 || isMajorWord(word) || (word.includes("-") && isMajorWord(word.split("-")[1]))) {
            return firstLetter + restOfWord;
        } else {
            return word.toLowerCase();
        }
    });

    return titleCasedWords.join(' ');
}

function toMonths(monthNumber: string): string {
    const monthIndex = parseInt(monthNumber, 10) - 1;
    const monthNames = [
        "Jan", "Feb", "Mar", "Apr", "May", "Jun",
        "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];

    if (monthIndex >= 0 && monthIndex < monthNames.length) {
        return monthNames[monthIndex];
    } else {
        throw new Error(`Invalid month number: ${monthNumber}`);
    }
}


const EssayList: React.FC = () => {
  const essayFiles = essayContext.keys();

  const essays = essayFiles.map((file: string) => {
    const without_md = file.slice(2, -3);
    console.log("without_md", without_md);
    const raw_title = without_md.split('__', 2)[1]
    const fileName = without_md.split('_', 2)[0]
    console.log("raw", raw_title, fileName);

    const title = raw_title !== undefined ? (toMonths(fileName.split('-')[1])+' 20'+fileName.split('-')[0]+' '+raw_title.replaceAll('_', ' ')) : toApaTitleCase(fileName.replace(/-/g, ' '));
    // if (raw_title === undefined) {
    //   const title = toApaTitleCase(fileName.replace(/-/g, ' '));
    // } else {
    //   const title = raw_title.replace('_', ' ')
    // }
    // const title = toApaTitleCase(fileName.replace(/-/g, ' '));
    return { fileName, title };
  });

  return (
    <ul className="essay-list">
    {essays.sort((a, b): number => {
      if(a.fileName > b.fileName)
        return -1;
      if(a.fileName < b.fileName)
        return 1;
      return 0;
    }).map((essay) => (
        <li key={essay.fileName}>
          <Link to={`/essays/${essay.fileName}`}>{essay.title}</Link>
        </li>
      ))}
    </ul>
  );
};

export default EssayList;
