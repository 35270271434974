import React from 'react';

const Title: React.FC = () => {
  return (
    <div>
      <h1>
        <b>J</b>ulia <b>Merz</b>
      </h1>
      <div className="links">
        <a href="/">Website</a>
        <a about="_blank" href="https://github.com/JuliaMerz">GitHub</a>
        <a about="_blank" href="https://twitter.com/mooncat_is">Twitter (mostly defunct)</a>
      </div>
    </div>
  );
};

export default Title;
