import React, {useState, useEffect} from 'react';

import profile from '../profile_pics/profile_0.jpg';
const profContext = require.context('../profile_pics', true, /\d\.jpg$/);
console.log('profctxt', profContext.keys());

const NUM_IMAGES = 19

const Profile: React.FC = () => {
  const [currentProfile, setCurrentProfile] = useState(0);
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null);

  const swapProfileImage = (reset: boolean) => {
    // Generate a random profile number between 1 and 15
    const newProfile = reset ? 0 : Math.floor(Math.random() * NUM_IMAGES) + 1;

    // Generate a random delay between 50ms and 500ms
    // first numer is if we have an alt image
    const minDelay = reset ? 4000 : 50;
    const maxDelay = reset ? 15000 : 500;
    const randomDelay = Math.floor(Math.random() * maxDelay) + minDelay;

    // Set the new profile image
    setCurrentProfile(newProfile);

    // Set a new timeout for the next swap
    const newTimeoutId = setTimeout(() => {swapProfileImage(!reset)}, randomDelay);
    setTimeoutId(newTimeoutId);
  };

  useEffect(() => {
    const minDelay = 3000;
    const maxDelay = 12000;
    const randomDelay = Math.floor(Math.random() * maxDelay) + minDelay;
    // Start swapping the profile images
    const newTimeoutId = setTimeout(() => swapProfileImage(false), randomDelay);
    setTimeoutId(newTimeoutId);

    // Cleanup function to stop the flashing when the component is unmounted
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, []);

  return (
    <div>
      <img className={currentProfile == 0 ? "normal" : "changed"} src={profContext('./profile_' + currentProfile + '.jpg')} alt="Profile" />
    </div>
  );
};

export default Profile;

