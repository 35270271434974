import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

const essayContext = require.context('../../essays/', true, /\.md$/);

const Essay: React.FC = () => {
  const { essayFilename } = useParams<{ essayFilename: string }>();
  const essayFile = essayContext.keys().find((file: string) => file.slice(2, -3).split('_', 1)[0] === essayFilename);

  const [essayContent, setEssayContent] = useState('');

  useEffect(() => {
    if (essayFile) {
      fetch(essayContext(essayFile))
        .then((response) => response.text())
        .then((text) => setEssayContent(text));
    }
  }, [essayFile]);

  if (!essayFile) {
    return <p>Essay not found</p>;
  }

  return (
    <div>
      <ReactMarkdown remarkPlugins={[remarkGfm]} children={essayContent} />
    </div>
  );
};

export default Essay;
